<template>
  <div>
    <v-simple-table class="table-padding-2-no-top table-h-36">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th colspan="12" class="fs-16px primary--text">Phụ liệu</th>
          </tr>
        </thead>
        <tbody>
          <tr class="font-weight-medium text-center">
            <th class="text-center" style="width: 105px">Phương án</th>
            <td style="width: 150px">{{ $t("labels.barcode") }}</td>
            <td>SKU</td>
            <td>Danh mục</td>
            <td>Tên</td>
            <td>Đặc tả</td>
            <td>Size</td>
            <td style="width: 100px">{{ $t("labels.quantity") }}</td>
            <td colspan="3">Cấp cho</td>
            <td style="width: 125px">
              <v-btn
                v-if="item.level === 0"
                x-small
                color="primary"
                @click="addConfig"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }} Phương án
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-for="(plan, index) in plans" :key="`p_${index}_${plan.key}`">
          <tr
            v-for="(val, key) in plan.items"
            :key="val.id"
            class="text-center"
          >
            <td v-if="key === 0" :rowspan="plan.items.length">
              {{ plan.name }} <br />
              <v-btn
                x-small
                class="mr-1"
                color="primary"
                @click="addPlanItem(plan, index)"
                :disabled="isDisabledPlanItemBtnAdd(index)"
              >
                {{ $t("labels.add") }}
              </v-btn>
              <v-btn
                x-small
                color="success"
                @click="save(plan)"
                :disabled="checkPlan(plan)"
              >
                {{ $t("labels.save") }}
              </v-btn>
            </td>
            <td>
              <template v-if="!val.id">
                <v-text-field
                  class="c-input-small"
                  v-model="val.code"
                  outlined
                  dense
                  hide-details
                  single-line
                  @keyup.enter="getItemDetail(index, val, key)"
                ></v-text-field>
              </template>

              <template v-else>
                {{ val.code }}
              </template>
            </td>
            <td>{{ val.sku }}</td>
            <td>{{ val.category_name }}</td>
            <td>{{ val.name }}</td>
            <td>{{ val.description }}</td>
            <td>{{ val.size }}</td>
            <td>
              <v-text-field
                v-if="!val.id"
                class="c-input-small"
                v-model.number="val.quantity"
                outlined
                dense
                hide-details
                single-line
                type="number"
              ></v-text-field>
              <span v-else>{{ val.quantity }}</span>
            </td>
            <td class="text-center vertical-align-middle" style="width: 100px">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="val.for_employee"
                  dense
                  :disabled="!val.editing"
                  class="mt-0 ml-1 c-input-small"
                  :value="1"
                  hide-details
                  label="Nhân viên"
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center vertical-align-middle" style="width: 100px">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="val.for_machiner"
                  dense
                  :disabled="!val.editing"
                  class="mt-0 ml-1 c-input-small"
                  :value="1"
                  hide-details
                  label="Gia công"
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center vertical-align-middle" style="width: 100px">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="val.for_servicer"
                  dense
                  :disabled="!val.editing"
                  class="mt-0 ml-1 c-input-small"
                  :value="1"
                  hide-details
                  label="Dịch vụ"
                ></v-checkbox>
              </div>
            </td>
            <td>
              <template v-if="val.editing">
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(index, val, key, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>

              <template v-else>
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(index, val, key, true)"
                  class="mr-1"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  v-if="item.level === 0"
                  @click="deactiveItem(val)"
                >
                  {{ $t("labels.delete") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { MATERIAL_CLASSIFY_ACCESSORY } from "@/libs/const";
import { debounce } from "lodash";

export default {
  name: "RecordDetailAccessory",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    semiFinished: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isLoading: false,
    categoryOptions: [],
    items: [],
    plans: [],
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    semiFinishedOptions() {
      return [...this.semiFinished].map((sf) => ({
        text: sf.name,
        value: sf.id,
      }));
    },
    groupItems() {
      const group = {};
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        const key = `${item.category_name}_${item.name}_${item.description}`;
        if (!group[key]) {
          group[key] = {
            total: 0,
            category_name: item.category_name,
            name: item.name,
            description: item.description,
            items: [],
          };
        }
        group[key].total += item.quantity;
        group[key].items.push({
          ...item,
        });
      }
      return group;
    },
    groupKeys() {
      return Object.keys(this.groupItems);
    },
  },
  created() {},
  mounted() {
    this.getPlanDetail();
  },
  methods: {
    isDisabledPlanItemBtnAdd(index) {
      const plans = [...this.plans];
      const plan = plans[index];
      const planItems = [...plan.items];
      const checkItem = [...planItems].find((item) => !item.id);
      return !!checkItem;
    },

    /* getList: debounce(function () {
            httpClient.post('/goods-production-accessory-list', {
                id_goods_production_record: this.item.id,
            }).then(({ data }) => {
                this.items = [...data]
            })
        }, 500), */

    /* toggleEditing(item, index, editing) {
            item.editing = editing
            if (!item.id) {
                this.items = [...this.items].filter((i, idx) => idx !== index)
            } else {
                this.items[index] = { ...item }
            }
            this.items = [...this.items]
        },

        addConfig() {
            if (this.isDisabledBtnAdd) {
                return false
            }
            this.items.unshift({
                id: null,
                id_material: null,
                code: null,
                quantity: null,
                for_employee: 0,
                for_machiner: 0,
                for_servicer: 0,
                editing: true,
            })
        }, */

    toggleEditing(index, item, key, editing) {
      item.editing = editing;
      const plans = [...this.plans];
      const plan = plans[index];
      let items = [...plan.items];
      if (!item.id) {
        items = [...items].filter((i, idx) => idx !== key);
      } else {
        items[key] = { ...item };
      }
      plans[index].items = [...items];
      this.plans = [...plans];
    },

    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      const items = [
        {
          id: null,
          id_material: null,
          code: null,
          sku: null,
          name: null,
          size: null,
          unit: null,
          editing: true,
        },
      ];
      this.plans.push({
        id: null,
        name: `Phương án ${this.plans.length + 1}`,
        editing: true,
        items,
      });
    },

    addPlanItem(item, index) {
      const plans = [...this.plans];
      plans[index].items.push({
        id: null,
        id_material: null,
        code: null,
        sku: null,
        name: null,
        size: null,
        unit: null,
        editing: true,
      });
      this.plans = [...this.plans];
    },

    checkPlan(plan) {
      const checkItem = [...plan.items].filter(
        (item) => !item.id_material || !item.sku || !item.quantity
      );
      return checkItem.length > 0;
    },

    getPlanDetail: debounce(function () {
      httpClient
        .post("/goods-production-accessory-plan", {
          id_goods_production_record: this.item.id,
        })
        .then(({ data }) => {
          this.plans = [...data];
          if (this.plans.length === 0) {
            this.addConfig();
          }
        });
    }, 500),

    async getItemDetail(index, item, key) {
      if (!item.code) {
        return false;
      }

      try {
        const { data } = await httpClient.post(
          "/material-get-by-code-and-classify",
          {
            code: item.code,
            classify: MATERIAL_CLASSIFY_ACCESSORY,
          }
        );
        const plans = [...this.plans];
        const plan = plans[index];
        const items = [...plan.items];
        items[key] = { ...item, ...data };
        plans[index].items = [...items];
        console.log(plans);
        this.plans = [...plans];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async save(plan) {
      if (this.checkPlan(plan)) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-accessory-plan-save", {
          id_goods_production_record: this.item.id,
          id_goods_production_record_accessory_plan: plan.id,
          name: plan.name,
          items: plan.items,
        });
        this.isLoading = false;
        const msg = this.$t("messages.saved");
        this.$vToastify.success(msg);
        this.getPlanDetail();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    /* async save(item) {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'))
                return false
            }

            this.isLoading = true

            try {
                await httpClient.post('/goods-production-accessory-save', {
                    id_goods_production_record: this.item.id,
                    id: item.id,
                    id_material: item.id_material,
                    quantity: item.quantity,
                    for_employee: item.for_employee,
                    for_machiner: item.for_machiner,
                    for_servicer: item.for_servicer,
                })
                this.isLoading = false
                const msg = this.$t('messages.saved')
                this.$vToastify.success(msg)
                this.getList()
            } catch (e) {
                this.isLoading = false
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
            }
        }, */

    async deactiveItem(item) {
      if (!confirm(this.$t("messages.are_you_sure_delete"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-record-deactive-detail", {
          id_goods_production_record: this.item.id,
          id: item.id,
          type: "accessories",
        });
        this.isLoading = false;
        const msg = this.$t("messages.saved");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
